import type { ToastRootProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';

export { default as Toaster } from './Toaster.vue';
export { default as Toast } from './Toast.vue';
export { default as ToastViewport } from './ToastViewport.vue';
export { default as ToastAction } from './ToastAction.vue';
export { default as ToastClose } from './ToastClose.vue';
export { default as ToastTitle } from './ToastTitle.vue';
export { default as ToastDescription } from './ToastDescription.vue';
export { default as ToastProvider } from './ToastProvider.vue';
export { default as ToastImage } from './ToastImage.vue';
export { default as ToastIcon } from './ToastIcon.vue';
export { toast, useToast } from './toast';

import { type VariantProps, cva } from 'class-variance-authority';

export const toastVariants = cva(
	/*tw*/ 'toast group pointer-events-auto relative font-semibold flex w-fit items-start px-xl py-md overflow-hidden rounded-xs border border-gray-200 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[--radix-toast-swipe-end-x] data-[swipe=move]:translate-x-[--radix-toast-swipe-move-x] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 lg:data-[state=closed]:slide-out-to-bottom-full data-[state=closed]:slide-out-to-top-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
	{
		variants: {
			variant: {
				default: /*tw*/ 'border bg-gray-100 text-black ',
				error:
					/*tw*/ 'destructive group border-error-500 bg-error-500 text-gray-50',
				'error-light':
					/*tw*/ 'destructive group border-error-200 bg-error-50 text-error-700',
				success:
					/*tw*/ 'destructive group border-success-500 bg-success-500 text-gray-50',
				info: /*tw*/ 'border-none bg-black/80 text-white',
				dark: /*tw*/ 'bg-black text-white !px-xl !py-md text-sm',
			},
			screen: {
				desktop: /*tw*/ 'items-start',
				mobile: /*tw*/ 'items-center justify-center',
			},
		},
		defaultVariants: {
			variant: 'default',
			screen: 'desktop',
		},
	},
);

export type ToastVariants = VariantProps<typeof toastVariants>;

export interface ToastProps extends ToastRootProps {
	class?: HTMLAttributes['class'];
	variant?: ToastVariants['variant'];
	screen?: ToastVariants['screen'];
	onOpenChange?: ((value: boolean) => void) | undefined;
}
